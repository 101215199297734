define("ember-awesome-macros/array/sort", ["exports", "ember-awesome-macros/array/-utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(array, sortDefinition) {
    let computedCallback;
    if (sortDefinition === undefined) {
      computedCallback = array => array.slice().sort();
    } else {
      computedCallback = function (array, sortDefinition) {
        let sortCallback;
        if (typeof sortDefinition === 'function') {
          sortCallback = sortDefinition.bind(this);
        } else {
          sortCallback = (a, b) => {
            let result = 0;

            // https://kangax.github.io/compat-table/es6/#test-generators
            // for (let key of sortDefinition) {
            for (let i = 0; i < sortDefinition.length; i++) {
              let key = sortDefinition[i];
              let [prop, direction] = key.split(':');
              result = Ember.compare(Ember.get(a, prop), Ember.get(b, prop));
              if (result !== 0) {
                if (direction === 'desc') {
                  result *= -1;
                }
                break;
              }
            }
            return result;
          };
        }
        return array.slice().sort(sortCallback);
      };
    }
    return (0, _utils.normalizeArray)({}, computedCallback)(array, sortDefinition);
  }
});