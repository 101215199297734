define("lodash/capitalize", ["exports", "lodash/toString", "lodash/upperFirst"], function (_exports, _toString, _upperFirst) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Converts the first character of `string` to upper case and the remaining
   * to lower case.
   *
   * @static
   * @memberOf _
   * @since 3.0.0
   * @category String
   * @param {string} [string=''] The string to capitalize.
   * @returns {string} Returns the capitalized string.
   * @example
   *
   * _.capitalize('FRED');
   * // => 'Fred'
   */
  function capitalize(string) {
    return (0, _upperFirst.default)((0, _toString.default)(string).toLowerCase());
  }
  var _default = _exports.default = capitalize;
});