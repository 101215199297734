define("ember-power-select/utils/computed-fallback-if-undefined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedFallbackIfUndefined;
  function computedFallbackIfUndefined(fallback) {
    return Ember.computed({
      get() {
        return fallback;
      },
      set(_, v) {
        return v === undefined ? fallback : v;
      }
    });
  }
});