define("ember-composable-helpers/helpers/dec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dec = dec;
  _exports.default = void 0;
  function dec([step, val]) {
    if (Ember.isEmpty(val)) {
      val = step;
      step = undefined;
    }
    val = Number(val);
    if (isNaN(val)) {
      return;
    }
    if (step === undefined) {
      step = 1;
    }
    return val - step;
  }
  var _default = _exports.default = Ember.Helper.helper(dec);
});