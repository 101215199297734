define("ember-macro-helpers/computed", ["exports", "ember-macro-helpers/-build-computed", "ember-macro-helpers/collapse-keys", "ember-macro-helpers/get-value", "ember-macro-helpers/flatten-keys"], function (_exports, _buildComputed, _collapseKeys, _getValue, _flattenKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _buildComputed.default)({
    collapseKeys: _collapseKeys.default,
    getValue: _getValue.default,
    flattenKeys: _flattenKeys.default
  });
});