define("ember-awesome-macros/xor", ["exports", "ember-awesome-macros/and", "ember-awesome-macros/nand", "ember-awesome-macros/or"], function (_exports, _and, _nand, _or) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    return (0, _and.default)((0, _or.default)(...arguments), (0, _nand.default)(...arguments));
  }
});