define("ember-power-select/templates/components/power-select/power-select-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mZjORmgY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"li\"],[14,0,\"ember-power-select-group\"],[15,\"aria-disabled\",[30,[36,1],[[35,0]],null]],[14,\"role\",\"option\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"ember-power-select-group-name\"],[12],[1,[34,2]],[13],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"disabled\",\"ember-power-select-true-string-if-present\",\"groupName\"]}",
    "moduleName": "ember-power-select/templates/components/power-select/power-select-group.hbs"
  });
});