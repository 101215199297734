define("liquid-fire/components/liquid-bind", ["exports", "liquid-fire/templates/components/liquid-bind"], function (_exports, _liquidBind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let LiquidBind = Ember.Component.extend({
    layout: _liquidBind.default,
    tagName: '',
    positionalParams: ['value'],
    // needed for Ember 1.13.[0-5] and 2.0.0-beta.[1-3] support
    forwardMatchContext: Ember.computed('matchContext', function () {
      let m = this.get('matchContext');
      if (!m) {
        m = {};
      }
      if (!m.helperName) {
        m.helperName = 'liquid-bind';
      }
      return m;
    })
  });
  LiquidBind.reopenClass({
    positionalParams: ['value']
  });
  var _default = _exports.default = LiquidBind;
});