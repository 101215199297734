define("ember-awesome-macros/-utils", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkArgs = checkArgs;
  _exports.deprecate = deprecate;
  _exports.reduceKeys = reduceKeys;
  _exports.reduceKeys2 = reduceKeys2;
  _exports.safelyCreateComputed = safelyCreateComputed;
  function reduceKeys(func) {
    return (0, _emberMacroHelpers.curriedComputed)((...values) => {
      if (values.length === 0) {
        return 0;
      }
      return values.reduce((total, next, i) => {
        if (Array.isArray(next)) {
          if (next.length === 0) {
            next = 0;
          } else {
            next = next.reduce(func);
          }
        }
        if (i === 0) {
          return next;
        }
        return func(total, next);
      }, null);
    });
  }
  function reduceKeys2(callback) {
    return (0, _emberMacroHelpers.lazyCurriedComputed)((get, ...keys) => {
      let last;
      for (let i = 0; i < keys.length; i++) {
        last = get(keys[i]);
        if (callback(last)) {
          return last;
        }
      }
      return last;
    });
  }
  function checkArgs(callback) {
    return (...values) => {
      for (let i = 0; i < values.length; i++) {
        if (values[i] === undefined) {
          return;
        }
      }
      return callback(...values);
    };
  }
  function safelyCreateComputed(funcStr) {
    return (0, _emberMacroHelpers.curriedComputed)(checkArgs((source, ...args) => {
      return source[funcStr](...args);
    }));
  }
  const projectName = 'ember-awesome-macros';
  const until = 'sometime before 1.0';
  function deprecate(newFunc, oldKey, newKey) {
    return Ember.deprecateFunc(`${oldKey} is deprecated, please use ${newKey}`, {
      id: `${projectName}.${oldKey}`,
      until
    }, newFunc);
  }
});