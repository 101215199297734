define("ember-composable-helpers/helpers/includes", ["exports", "ember-composable-helpers/utils/as-array"], function (_exports, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.includes = includes;
  function includes(needleOrNeedles, haystack) {
    if (!Ember.isArray(haystack)) {
      return false;
    }
    let needles = Ember.isArray(needleOrNeedles) ? needleOrNeedles : [needleOrNeedles];
    let haystackAsEmberArray = Ember.A((0, _asArray.default)(haystack));
    return (0, _asArray.default)(needles).every(needle => {
      return haystackAsEmberArray.includes(needle);
    });
  }
  var _default = _exports.default = Ember.Helper.helper(function ([needle, haystack]) {
    return includes(needle, haystack);
  });
});