define("ember-data-has-many-query/property-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stickyPropertyName = _exports.queryParamPropertyName = _exports.queryIdPropertyName = _exports.lastWasErrorPropertyName = _exports.ajaxOptionsPropertyName = void 0;
  const queryParamPropertyName = function (key) {
    return '__' + key + 'QueryParams';
  };
  _exports.queryParamPropertyName = queryParamPropertyName;
  const queryIdPropertyName = function (key) {
    return '__' + key + 'QueryId';
  };
  _exports.queryIdPropertyName = queryIdPropertyName;
  const lastWasErrorPropertyName = function (key) {
    return '__' + key + 'LastWasError';
  };
  _exports.lastWasErrorPropertyName = lastWasErrorPropertyName;
  const ajaxOptionsPropertyName = function (key) {
    return '__' + key + 'AjaxOptions';
  };
  _exports.ajaxOptionsPropertyName = ajaxOptionsPropertyName;
  const stickyPropertyName = function (key) {
    return '__' + key + 'BelongsToSticky';
  };
  _exports.stickyPropertyName = stickyPropertyName;
});