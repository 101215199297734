define("ember-assign-helper/helpers/assign", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.assign = assign;
  function assign(params) {
    return Ember.assign({}, ...params);
  }

  exports.default = Ember.Helper.helper(assign);
});