define("ember-is-mobile/services/is-mobile", ["exports", "ember-is-mobile"], function (_exports, _emberIsMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * The attributes returned by isMobile are accessible. However, they should be
   * accessed using the `get` helper, since they may be undefined if the user
   * agent header is blank.
   */
  class IsMobileService extends Ember.Service {
    get fastboot() {
      return Ember.getOwner(this).lookup('service:fastboot');
    }
    constructor() {
      super(...arguments);
      _defineProperty(this, "tests", {});
      let tests;
      if (this.fastboot !== undefined && this.fastboot.isFastBoot) {
        let headers = this.fastboot.request.headers;
        let userAgent = headers.headers['user-agent'];

        // isMobile tries to fetch `navigator` if we give it a blank user agent.
        if (Ember.isBlank(userAgent)) {
          return;
        }
        tests = (0, _emberIsMobile.default)(userAgent[0]);
      } else {
        tests = (0, _emberIsMobile.default)();
      }
      for (let media in tests) {
        this[media] = tests[media];
      }
      this.tests = tests;
    }
  }
  _exports.default = IsMobileService;
});