define("ember-cp-validations/utils/should-call-super", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = shouldCallSuper;
  /**
   * Checks if the give key exists on the object's super.
   * If so, we can successfully call the obj[key] _super
   *
   * Created by @rwjblue
   */
  function shouldCallSuper(obj, key) {
    let current = Object.getPrototypeOf(obj);
    current = Object.getPrototypeOf(current);
    while (current) {
      let descriptor = Object.getOwnPropertyDescriptor(current, key);
      if (descriptor) {
        return true;
      }
      current = Object.getPrototypeOf(current);
    }
    return false;
  }
});