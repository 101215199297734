define("ember-require-module/index", ["exports", "require"], function (_exports, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = requireModule;
  function requireModule(module, exportName = 'default') {
    if (_require.default.has(module)) {
      return (0, _require.default)(module)[exportName];
    }
  }
});