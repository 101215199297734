define('paper-data-table/components/paper-data-table-head', ['exports', 'paper-data-table/templates/components/paper-data-table-head'], function (exports, _paperDataTableHead) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		layout: _paperDataTableHead.default,
		tagName: '',
		cellpadding: '0',
		cellspacing: '0'
	});
});