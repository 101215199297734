define("lodash/_metaMap", ["exports", "lodash/_WeakMap"], function (_exports, _WeakMap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** Used to store function metadata. */
  var metaMap = _WeakMap.default && new _WeakMap.default();
  var _default = _exports.default = metaMap;
});