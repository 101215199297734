define("ember-awesome-macros/lte", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberMacroHelpers.curriedComputed)((val1, val2) => {
    return val1 <= val2;
  });
});