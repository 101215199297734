define("lodash/_copySymbolsIn", ["exports", "lodash/_copyObject", "lodash/_getSymbolsIn"], function (_exports, _copyObject, _getSymbolsIn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Copies own and inherited symbols of `source` to `object`.
   *
   * @private
   * @param {Object} source The object to copy symbols from.
   * @param {Object} [object={}] The object to copy symbols to.
   * @returns {Object} Returns `object`.
   */
  function copySymbolsIn(source, object) {
    return (0, _copyObject.default)(source, (0, _getSymbolsIn.default)(source), object);
  }
  var _default = _exports.default = copySymbolsIn;
});