define("ember-i18n-iso-countries/langs/fi", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "AF": "Afganistan",
    "AX": "Ahvenanmaa",
    "NL": "Alankomaat",
    "AN": "Alankomaiden Antillit",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "Amerikan Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarktis",
    "AG": "Antigua ja Barbuda",
    "AE": "Arabiemiirikunnat",
    "AR": "Argentiina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AC": "Ascension Island",
    "AU": "Australia",
    "AZ": "Azerbaidžan",
    "BS": "Bahama",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BE": "Belgia",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BQ": "Bonaire, Sint Eustatius ja Saba",
    "BA": "Bosnia ja Hertsegovina",
    "BW": "Botswana",
    "BV": "Bouvet’nsaari",
    "BR": "Brasilia",
    "IO": "Brittiläinen Intian valtameren alue",
    "VG": "Brittiläiset Neitsytsaaret",
    "BN": "Brunei",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KY": "Caymansaaret",
    "EA": "Ceuta, Melilla",
    "CL": "Chile",
    "CP": "Clippertoninsaari",
    "CK": "Cookinsaaret",
    "CR": "Costa Rica",
    "CW": "Curaçao",
    "DG": "Diego Garcia",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominikaaninen tasavalta",
    "EC": "Ecuador",
    "EG": "Egypti",
    "SV": "El Salvador",
    "ER": "Eritrea",
    "ES": "Espanja",
    "ET": "Etiopia",
    "ZA": "Etelä-Afrikka",
    "GS": "Etelä-Georgia ja Eteläiset Sandwichsaaret",
    "SS": "Etelä-Sudan",
    "EU": "Euroopan unioni",
    "FK": "Falklandinsaaret",
    "FO": "Färsaaret",
    "FJ": "Fidži",
    "PH": "Filippiinit",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GD": "Grenada",
    "GL": "Grönlanti",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard ja McDonaldinsaaret",
    "HN": "Honduras",
    "HK": "Hongkong",
    "ID": "Indonesia",
    "IN": "Intia",
    "IQ": "Irak",
    "IR": "Iran",
    "IE": "Irlanti",
    "IS": "Islanti",
    "IL": "Israel",
    "IT": "Italia",
    "TL": "Itä-Timor",
    "AT": "Itävalta",
    "JM": "Jamaika",
    "JP": "Japani",
    "YE": "Jemen",
    "JE": "Jersey",
    "JO": "Jordania",
    "CX": "Joulusaari",
    "KH": "Kambodža",
    "CM": "Kamerun",
    "CA": "Kanada",
    "IC": "Kanariansaaret",
    "CV": "Kap Verde",
    "KZ": "Kazakstan",
    "KE": "Kenia",
    "CF": "Keski-Afrikan tasavalta",
    "CN": "Kiina",
    "KG": "Kirgisia",
    "KI": "Kiribati",
    "CO": "Kolumbia",
    "KM": "Komorit",
    "CD": "Kongon demokraattinen tasavalta",
    "CG": "Kongon tasavalta",
    "CC": "Kookossaaret",
    "KP": "Korean demokraattinen kansantasavalta",
    "KR": "Korean tasavalta",
    "GR": "Kreikka",
    "HR": "Kroatia",
    "CU": "Kuuba",
    "KW": "Kuwait",
    "CY": "Kypros",
    "LA": "Laos",
    "LV": "Latvia",
    "LS": "Lesotho",
    "LB": "Libanon",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Liettua",
    "LU": "Luxemburg",
    "EH": "Länsi-Sahara",
    "MO": "Macao",
    "MG": "Madagaskar",
    "MK": "Makedonia",
    "MW": "Malawi",
    "MV": "Malediivit",
    "MY": "Malesia",
    "ML": "Mali",
    "MT": "Malta",
    "IM": "Mansaari",
    "MA": "Marokko",
    "MH": "Marshallinsaaret",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Meksiko",
    "FM": "Mikronesian liittovaltio",
    "MD": "Moldova",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MZ": "Mosambik",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolkinsaari",
    "NO": "Norja",
    "CI": "Norsunluurannikko",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestiina",
    "PA": "Panama",
    "PG": "Papua-Uusi-Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "MP": "Pohjois-Mariaanit",
    "PN": "Pitcairn",
    "PT": "Portugali",
    "PR": "Puerto Rico",
    "PL": "Puola",
    "GQ": "Päiväntasaajan Guinea",
    "QA": "Qatar",
    "FR": "Ranska",
    "FX": "Ranska (Eurooppaan kuuluvat osat)",
    "TF": "Ranskan eteläiset alueet",
    "GF": "Ranskan Guayana",
    "PF": "Ranskan Polynesia",
    "RE": "Réunion",
    "RO": "Romania",
    "RW": "Ruanda",
    "SE": "Ruotsi",
    "BL": "Saint-Barthélemy",
    "SH": "Saint Helena",
    "KN": "Saint Kitts ja Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint-Martin",
    "PM": "Saint-Pierre ja Miquelon",
    "VC": "Saint Vincent ja Grenadiinit",
    "DE": "Saksa",
    "SB": "Salomonsaaret",
    "ZM": "Sambia",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "São Tomé ja Príncipe",
    "SA": "Saudi-Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychellit",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SO": "Somalia",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "FI": "Suomi",
    "SR": "Suriname",
    "SJ": "Svalbard ja Jan Mayen",
    "SZ": "Swazimaa",
    "CH": "Sveitsi",
    "SY": "Syyria",
    "TJ": "Tadžikistan",
    "TW": "Taiwan",
    "TZ": "Tansania",
    "DK": "Tanska",
    "TH": "Thaimaa",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad ja Tobago",
    "TA": "Tristan da Cunha",
    "TD": "Tšad",
    "CZ": "Tšekki",
    "TN": "Tunisia",
    "TR": "Turkki",
    "TM": "Turkmenistan",
    "TC": "Turks- ja Caicossaaret",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraina",
    "HU": "Unkari",
    "UY": "Uruguay",
    "NC": "Uusi-Kaledonia",
    "NZ": "Uusi-Seelanti",
    "UZ": "Uzbekistan",
    "BY": "Valko-Venäjä",
    "VU": "Vanuatu",
    "VA": "Vatikaanivaltio",
    "VE": "Venezuela",
    "RU": "Venäjä",
    "VN": "Vietnam",
    "EE": "Viro",
    "WF": "Wallis ja Futunasaaret",
    "GB": "Yhdistynyt kuningaskunta",
    "UK": "Yhdistynyt kuningaskunta",
    "US": "Yhdysvallat",
    "VI": "Yhdysvaltain Neitsytsaaret",
    "UM": "Yhdysvaltain pienet erillissaaret",
    "ZW": "Zimbabwe"
  };
});