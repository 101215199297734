define("lodash/_baseShuffle", ["exports", "lodash/_shuffleSelf", "lodash/values"], function (_exports, _shuffleSelf, _values) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The base implementation of `_.shuffle`.
   *
   * @private
   * @param {Array|Object} collection The collection to shuffle.
   * @returns {Array} Returns the new shuffled array.
   */
  function baseShuffle(collection) {
    return (0, _shuffleSelf.default)((0, _values.default)(collection));
  }
  var _default = _exports.default = baseShuffle;
});