define("ember-power-select/helpers/ember-power-select-true-string-if-present", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberPowerSelectTrueStringIfPresent = emberPowerSelectTrueStringIfPresent;
  function emberPowerSelectTrueStringIfPresent([bool] /* , hash*/) {
    return bool ? 'true' : false;
  }
  var _default = _exports.default = Ember.Helper.helper(emberPowerSelectTrueStringIfPresent);
});