define("liquid-fire/transitions/wait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(ms, opts, ...rest) {
    opts = opts !== undefined ? opts : {};
    return new Ember.RSVP.Promise(resolve => {
      setTimeout(() => {
        resolve(this.lookup(opts.then || 'default').call(this, ...rest));
      }, ms);
    });
  }
});