define("lodash/_DataView", ["exports", "lodash/_getNative", "lodash/_root"], function (_exports, _getNative, _root) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* Built-in method references that are verified to be native. */
  var DataView = (0, _getNative.default)(_root.default, 'DataView');
  var _default = _exports.default = DataView;
});