define("liquid-fire/transitions/scale", ["exports", "liquid-fire"], function (_exports, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scale;
  function scale(opts = {}) {
    return (0, _liquidFire.animate)(this.oldElement, {
      scale: [0.2, 1]
    }, opts).then(() => {
      return (0, _liquidFire.animate)(this.newElement, {
        scale: [1, 0.2]
      }, opts);
    });
  }
});