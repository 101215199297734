define("ember-awesome-macros/unless", ["exports", "ember-awesome-macros"], function (_exports, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(condition, expr1, expr2) {
    return (0, _emberAwesomeMacros.conditional)(condition, expr2, expr1);
  }
});