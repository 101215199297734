define("ember-awesome-macros/divide", ["exports", "ember-awesome-macros/quotient"], function (_exports, _quotient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _quotient.default;
    }
  });
});