define("ember-composable-helpers/utils/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isEqual;
  function isEqual(firstValue, secondValue, useDeepEqual = false) {
    if (useDeepEqual) {
      return JSON.stringify(firstValue) === JSON.stringify(secondValue);
    } else {
      return Ember.isEqual(firstValue, secondValue) || Ember.isEqual(secondValue, firstValue);
    }
  }
});