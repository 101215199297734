define("lodash/_baseEachRight", ["exports", "lodash/_baseForOwnRight", "lodash/_createBaseEach"], function (_exports, _baseForOwnRight, _createBaseEach) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The base implementation of `_.forEachRight` without support for iteratee shorthands.
   *
   * @private
   * @param {Array|Object} collection The collection to iterate over.
   * @param {Function} iteratee The function invoked per iteration.
   * @returns {Array|Object} Returns `collection`.
   */
  var baseEachRight = (0, _createBaseEach.default)(_baseForOwnRight.default, true);
  var _default = _exports.default = baseEachRight;
});