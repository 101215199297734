define("lodash/_castArrayLikeObject", ["exports", "lodash/isArrayLikeObject"], function (_exports, _isArrayLikeObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Casts `value` to an empty array if it's not an array like object.
   *
   * @private
   * @param {*} value The value to inspect.
   * @returns {Array|Object} Returns the cast array-like object.
   */
  function castArrayLikeObject(value) {
    return (0, _isArrayLikeObject.default)(value) ? value : [];
  }
  var _default = _exports.default = castArrayLikeObject;
});