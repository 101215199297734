define("lodash/_baseIntersection", ["exports", "lodash/_SetCache", "lodash/_arrayIncludes", "lodash/_arrayIncludesWith", "lodash/_arrayMap", "lodash/_baseUnary", "lodash/_cacheHas"], function (_exports, _SetCache, _arrayIncludes, _arrayIncludesWith, _arrayMap, _baseUnary, _cacheHas) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* Built-in method references for those with the same name as other `lodash` methods. */
  var nativeMin = Math.min;

  /**
   * The base implementation of methods like `_.intersection`, without support
   * for iteratee shorthands, that accepts an array of arrays to inspect.
   *
   * @private
   * @param {Array} arrays The arrays to inspect.
   * @param {Function} [iteratee] The iteratee invoked per element.
   * @param {Function} [comparator] The comparator invoked per element.
   * @returns {Array} Returns the new array of shared values.
   */
  function baseIntersection(arrays, iteratee, comparator) {
    var includes = comparator ? _arrayIncludesWith.default : _arrayIncludes.default,
      length = arrays[0].length,
      othLength = arrays.length,
      othIndex = othLength,
      caches = Array(othLength),
      maxLength = Infinity,
      result = [];
    while (othIndex--) {
      var array = arrays[othIndex];
      if (othIndex && iteratee) {
        array = (0, _arrayMap.default)(array, (0, _baseUnary.default)(iteratee));
      }
      maxLength = nativeMin(array.length, maxLength);
      caches[othIndex] = !comparator && (iteratee || length >= 120 && array.length >= 120) ? new _SetCache.default(othIndex && array) : undefined;
    }
    array = arrays[0];
    var index = -1,
      seen = caches[0];
    outer: while (++index < length && result.length < maxLength) {
      var value = array[index],
        computed = iteratee ? iteratee(value) : value;
      value = comparator || value !== 0 ? value : 0;
      if (!(seen ? (0, _cacheHas.default)(seen, computed) : includes(result, computed, comparator))) {
        othIndex = othLength;
        while (--othIndex) {
          var cache = caches[othIndex];
          if (!(cache ? (0, _cacheHas.default)(cache, computed) : includes(arrays[othIndex], computed, comparator))) {
            continue outer;
          }
        }
        if (seen) {
          seen.push(computed);
        }
        result.push(value);
      }
    }
    return result;
  }
  var _default = _exports.default = baseIntersection;
});