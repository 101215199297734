define("ember-data-extensions/mixins/model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const resetRelations = function (record) {
    Object.keys(record.get('__recordsJustSaved') || {}).forEach(relationName => {
      let relationRecords = record.get('__recordsJustSaved')[relationName];
      relationRecords.forEach(({
        parent,
        relatedRecord
      }) => {
        let r = relatedRecord;
        let shouldUnload = r.get('isNew') || r.get('markedForDestruction');
        let relation = parent.get(relationName);
        if (shouldUnload) {
          if (Ember.isPresent(relation)) {
            relation.removeObject(r);
          }
          r.unloadRecord();
        } else if (r.get('markedForDeletion')) {
          if (Ember.isPresent(relation)) {
            relation.removeObject(r);
          }
          r.set('markedForDeletion', false);
        }
      });
    });
    record.set('__recordsJustSaved', []);
    return record;
  };
  const defaultOptions = function (options) {
    if (options.resetRelations !== false) {
      options.resetRelations = true;
    }
  };
  var _default = _exports.default = Ember.Mixin.create({
    hasDirtyAttributes: Ember.computed('currentState.isDirty', 'markedForDestruction', 'markedForDeletion', '_manyToOneDeleted.[]', function () {
      let original = this._super(...arguments);
      return original || this.markedForDestruction || this.markedForDeletion || (this._manyToOneDeleted || []).length > 0;
    }),
    markedForDeletion: Ember.computed('_markedForDeletion', function () {
      return this._markedForDeletion || false;
    }),
    markedForDestruction: Ember.computed('_markedForDestruction', function () {
      return this._markedForDestruction || false;
    }),
    markForDeletion() {
      this.set('_markedForDeletion', true);
    },
    unmarkForDeletion() {
      this.set('_markedForDeletion', false);
    },
    markForDestruction() {
      if (!this.id) {
        throw new Error(`${this.constructor.modelName} should not be marked for destruction without id.`);
      }
      this.set('_markedForDestruction', true);
    },
    unmarkForDestruction() {
      this.set('_markedForDestruction', false);
    },
    markManyToOneDeletion(relation) {
      let deletedRelations = this._manyToOneDeleted;
      if (!deletedRelations) {
        this.set('_manyToOneDeleted', Ember.A());
        deletedRelations = this._manyToOneDeleted;
      }
      if (!deletedRelations.includes(relation)) {
        deletedRelations.pushObject(relation);
      }
    },
    unmarkManyToOneDeletion(relation) {
      return this.markedForManyToOneDeletion(relation) && this._manyToOneDeleted.removeObject(relation);
    },
    markedForManyToOneDeletion(relation) {
      return this._manyToOneDeleted && this._manyToOneDeleted.includes(relation);
    },
    markManyToManyDeletion(relation, model) {
      let deletedRelations = this._manyToManyDeleted;
      if (!deletedRelations) {
        this.set('_manyToManyDeleted', Ember.Object.create());
        deletedRelations = this._manyToManyDeleted;
      }
      if (!deletedRelations.get(relation)) {
        deletedRelations.set(relation, Ember.A());
        Ember.defineProperty(this, `manyToManyDeleted${relation}`, Ember.computed.readOnly(`_manyToManyDeleted.${relation}`));
      }
      if (!deletedRelations.get(relation).includes(model)) {
        deletedRelations.get(relation).pushObject(model);
      }
    },
    manyToManyMarkedForDeletionModels(relation) {
      const relationModels = this._manyToManyDeleted && this.get(`_manyToManyDeleted.${relation}`);
      return relationModels && relationModels.toArray() || [];
    },
    unmarkManyToManyDeletion(relation, model) {
      return this._manyToManyDeleted && this.get(`_manyToManyDeleted.${relation}`) && this.get(`_manyToManyDeleted.${relation}`).removeObject(model);
    },
    tempId() {
      if (!this._tempId) {
        this._tempId = `${Ember.guidFor(this)}-${+new Date()}`;
      }
      return this._tempId;
    },
    jsonapiType() {
      return this.store.adapterFor(this.constructor.modelName).pathForType(this.constructor.modelName);
    },
    // Blank out all relations after saving
    // We will use the server response includes to 'reset'
    // these relations
    save(options = {}) {
      defaultOptions(options);
      let promise = this._super(...arguments);
      if (options.resetRelations === true) {
        return promise.then(resetRelations);
      }
      return promise;
    }
  });
});