define("ember-cli-clipboard/helpers/is-clipboard-supported", ["exports", "clipboard"], function (_exports, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IsClipboardSupportedHelper extends Ember.Helper {
    constructor() {
      super(...arguments);
      const service = Ember.getOwner(this).lookup('service:fastboot');
      this.isFastBoot = service ? service.isFastBoot : false;
    }
    compute([action]) {
      const {
        isFastBoot
      } = this;
      return isFastBoot ? false : _clipboard.default.isSupported(action);
    }
  }
  _exports.default = IsClipboardSupportedHelper;
});