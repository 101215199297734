define("ember-awesome-macros/equal", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberMacroHelpers.lazyCurriedComputed)((get, firstKey, ...keys) => {
    let firstVal = get(firstKey);
    for (let i = 0; i < keys.length; i++) {
      if (firstVal !== get(keys[i])) {
        return false;
      }
    }
    return true;
  });
});