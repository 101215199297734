define("lodash/_defineProperty", ["exports", "lodash/_getNative"], function (_exports, _getNative) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defineProperty = function () {
    try {
      var func = (0, _getNative.default)(Object, 'defineProperty');
      func({}, '', {});
      return func;
    } catch (e) {
      return null;
    }
  }();
  var _default = _exports.default = defineProperty;
});