define("lodash/_baseConforms", ["exports", "lodash/_baseConformsTo", "lodash/keys"], function (_exports, _baseConformsTo, _keys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The base implementation of `_.conforms` which doesn't clone `source`.
   *
   * @private
   * @param {Object} source The object of property predicates to conform to.
   * @returns {Function} Returns the new spec function.
   */
  function baseConforms(source) {
    var props = (0, _keys.default)(source);
    return function (object) {
      return (0, _baseConformsTo.default)(object, source, props);
    };
  }
  var _default = _exports.default = baseConforms;
});