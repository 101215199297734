define('paper-data-table/components/paper-data-table', ['exports', 'paper-data-table/templates/components/paper-data-table'], function (exports, _paperDataTable) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		layout: _paperDataTable.default,
		tagName: 'md-table-container',
		classNameBindings: ['noPadding:no-padding-table'],
		bodyComponent: 'paper-data-table-body',
		bodyRowComponent: 'paper-data-table-row',
		headComponent: 'paper-data-table-head',
		rowWidth: 0,
		sortProp: '',
		sortDir: 'asc',
		selectable: false,

		sortDesc: Ember.computed('sortProp', 'sortDir', function () {
			let sortDesc = this.getProperties('sortProp', 'sortDir');
			return `${sortDesc.sortProp}:${sortDesc.sortDir}`;
		}).readOnly(),

		actions: {
			sortChanged(sortProp, sortDir) {
				if (this.get('onChangeSort')) {
					this.get('onChangeSort')({ sortProp, sortDir });
				} else {
					this.setProperties({ sortProp, sortDir });
				}
			}
		}
	});
});