define("lodash/_coreJsData", ["exports", "lodash/_root"], function (_exports, _root) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** Used to detect overreaching core-js shims. */
  var coreJsData = _root.default['__core-js_shared__'];
  var _default = _exports.default = coreJsData;
});