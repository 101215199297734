define('perf-primitives/hash-map', ['exports', 'perf-primitives/empty-object', 'perf-primitives/-constants'], function (exports, _emptyObject, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  class HashMap {

    constructor(entries) {
      this._data = new _emptyObject.default();

      if (entries) {
        for (let i = 0; i < entries.length; i++) {
          this.data[entries[i][0]] = entries[i][1];
        }
      }
    }

    forEach(cb) {
      for (let key in this._data) {
        // skip undefined
        if (this._data[key] !== _constants.UNDEFINED_KEY) {
          cb(this._data[key], key);
        }
      }

      return this;
    }

    get(key) {
      let val = this._data[key];

      return val === _constants.UNDEFINED_KEY ? undefined : val;
    }

    set(key, value) {
      this._data[key] = value;

      return this;
    }

    delete(key) {
      this._data[key] = _constants.UNDEFINED_KEY;

      return true;
    }

  }
  exports.default = HashMap;
});