define('paper-data-table/components/paper-data-table-column', ['exports', 'paper-data-table/templates/components/paper-data-table-column'], function (exports, _paperDataTableColumn) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	const {
		Handlebars: { Utils: { escapeExpression } }
	} = Ember;

	exports.default = Ember.Component.extend({
		layout: _paperDataTableColumn.default,
		tagName: 'th',
		classNameBindings: ['numeric:md-numeric', 'active:md-active', 'sortProp:md-sort'],
		attributeBindings: ['style', 'colspan'],
		classNames: ['md-column'],
		currentProp: null,
		sortProp: null,
		sortDir: null,

		style: Ember.computed('width', function () {
			let width = escapeExpression(this.get('width'));
			if (width) {
				return Ember.String.htmlSafe(`width: ${width}px;`);
			}
			return undefined;
		}),

		active: Ember.computed('sortProp', 'currentProp', function () {
			return this.get('sortProp') && this.get('sortProp') === this.get('currentProp');
		}).readOnly(),

		click() {
			let {
				sortProp,
				sortDir,
				active } = this.getProperties('sortProp', 'sortDir', 'active');

			if (!sortProp) {
				return;
			}

			let newSortDir = sortDir;
			if (!active) {
				newSortDir = 'asc';
			} else {
				newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
			}

			this.get('sortChanged')(sortProp, newSortDir);
			this.set('sortDir', newSortDir);
		}
	});
});