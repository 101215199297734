define("ember-power-select/templates/components/power-select/before-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "obMpxAf9",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ember-power-select-search\"],[12],[2,\"\\n    \"],[10,\"input\"],[14,\"autocomplete\",\"off\"],[14,\"autocorrect\",\"off\"],[14,\"autocapitalize\",\"off\"],[14,\"spellcheck\",\"false\"],[14,\"role\",\"combobox\"],[14,0,\"ember-power-select-search-input\"],[15,2,[34,0,[\"searchText\"]]],[15,\"aria-controls\",[34,1]],[15,\"placeholder\",[34,2]],[15,\"oninput\",[34,3]],[15,\"onfocus\",[34,4]],[15,\"onblur\",[34,5]],[15,\"onkeydown\",[30,[36,6],[[32,0],\"onKeydown\"],null]],[14,4,\"search\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"select\",\"listboxId\",\"searchPlaceholder\",\"onInput\",\"onFocus\",\"onBlur\",\"action\",\"searchEnabled\",\"if\"]}",
    "moduleName": "ember-power-select/templates/components/power-select/before-options.hbs"
  });
});