define("ember-router-helpers/utils/track-active-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = trackActiveRoute;
  function trackActiveRoute(router) {
    // ensure we recompute anytime `router.currentURL` changes
    router.currentURL;

    // ensure we recompute whenever the `router.currentRouteName` changes
    // this is slightly overlapping with router.currentURL but there are
    // cases where route.currentURL doesn't change but the
    // router.currentRouteName has (e.g. loading and error states)
    router.currentRouteName;
  }
});