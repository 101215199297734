define('paper-data-table/components/paper-data-table-pagination', ['exports', 'paper-data-table/templates/components/paper-data-table-pagination'], function (exports, _paperDataTablePagination) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		layout: _paperDataTablePagination.default,
		tagName: 'md-table-pagination',
		classNames: ['md-table-pagination'],
		startOffset: Ember.computed('page', 'limit', function () {
			return Math.max((this.get('page') - 1) * this.get('limit') + 1, 1); // 1-based index
		}),
		endOffset: Ember.computed('startOffset', 'limit', 'total', function () {
			let endOffset = this.get('startOffset') + this.get('limit');
			let total = this.get('total');
			return total ? Math.min(endOffset, total) : endOffset;
		})
	});
});