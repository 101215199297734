define("ember-awesome-macros/array/group-by", ["exports", "ember-awesome-macros/array/-utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getCurrentGroup(groups, value, comparator) {
    if (comparator) {
      return groups.find(group => {
        return comparator(Ember.get(group, 'value'), value);
      });
    }
    return groups.findBy('value', value);
  }
  var _default = _exports.default = (0, _utils.normalizeArray3)({
    firstDefault: () => [],
    func(array, key, comparator) {
      let groups = Ember.A();
      array.forEach(item => {
        let value = Ember.get(item, key);
        let currentGroup = getCurrentGroup(groups, value, comparator);
        if (currentGroup) {
          currentGroup.items.push(item);
        } else {
          groups.push({
            key,
            value,
            items: [item]
          });
        }
      });
      return groups;
    }
  });
});