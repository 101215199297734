define("liquid-fire/templates/components/illiquid-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+EXzNJSr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[35,0]]]],\"hasEval\":false,\"upvars\":[\"_fixedModel\"]}",
    "moduleName": "liquid-fire/templates/components/illiquid-model.hbs"
  });
});