define("lodash/number.default", ["exports", "lodash/clamp", "lodash/inRange", "lodash/random"], function (_exports, _clamp, _inRange, _random) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    clamp: _clamp.default,
    inRange: _inRange.default,
    random: _random.default
  };
});