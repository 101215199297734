define("ember-resource-metadata/helpers/meta-for-resource", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    resourceMetadata: Ember.inject.service(),
    compute([thing]) {
      return this.get('resourceMetadata').read(thing);
    }
  });
});