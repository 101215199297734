define("ember-inputmask/components/zip-code-input", ["exports", "ember-inputmask/components/input-mask"], function (_exports, _inputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * `{{zip-code-input}}` component.
   *
   * Displays an input that masks a US ZIP code.
   *
   * Future: Add config options that allow users to set locality
   * app wide.
   *
   * OPTIONS:
   *   fullCode - bool
   *     Allows users to optionally enter the full ZIP+4 area code.
   *
   * @class ZipCodeInput
   */
  var _default = _exports.default = _inputMask.default.extend({
    mask: '99999',
    fullCode: false,
    oldComponent: '{{zip-code-input}}',
    newComponent: '{{one-way-zip-code-mask}}',
    updateMask: function () {
      if (this.get('fullCode')) {
        this.set('mask', '99999[-9999]');
      }
      this._super();
    },
    _maskShouldChange: Ember.observer('mask', 'fullCode', function () {
      Ember.run.once(this, 'updateMask');
    })
  });
});