define("lodash/_stackClear", ["exports", "lodash/_ListCache"], function (_exports, _ListCache) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Removes all key-value entries from the stack.
   *
   * @private
   * @name clear
   * @memberOf Stack
   */
  function stackClear() {
    this.__data__ = new _ListCache.default();
    this.size = 0;
  }
  var _default = _exports.default = stackClear;
});