define("lodash/overSome", ["exports", "lodash/_arraySome", "lodash/_createOver"], function (_exports, _arraySome, _createOver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Creates a function that checks if **any** of the `predicates` return
   * truthy when invoked with the arguments it receives.
   *
   * Following shorthands are possible for providing predicates.
   * Pass an `Object` and it will be used as an parameter for `_.matches` to create the predicate.
   * Pass an `Array` of parameters for `_.matchesProperty` and the predicate will be created using them.
   *
   * @static
   * @memberOf _
   * @since 4.0.0
   * @category Util
   * @param {...(Function|Function[])} [predicates=[_.identity]]
   *  The predicates to check.
   * @returns {Function} Returns the new function.
   * @example
   *
   * var func = _.overSome([Boolean, isFinite]);
   *
   * func('1');
   * // => true
   *
   * func(null);
   * // => true
   *
   * func(NaN);
   * // => false
   *
   * var matchesFunc = _.overSome([{ 'a': 1 }, { 'a': 2 }])
   * var matchesPropertyFunc = _.overSome([['a', 1], ['a', 2]])
   */
  var overSome = (0, _createOver.default)(_arraySome.default);
  var _default = _exports.default = overSome;
});