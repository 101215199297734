define("liquid-fire/is-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isBrowser;
  function isBrowser() {
    return typeof window !== 'undefined' && window && typeof document !== 'undefined' && document;
  }
});