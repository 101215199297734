define("ember-ajax/-private/utils/is-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isString;
  function isString(object) {
    return typeof object === 'string';
  }
});