define('ember-href-to/helpers/href-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function hrefTo(context, targetRouteName, ...rest) {
    let router = Ember.getOwner(context).lookup('service:router');

    if (router === undefined) {
      return;
    }

    let lastParam = rest[rest.length - 1];

    let queryParams = {};
    if (lastParam && lastParam.isQueryParams) {
      queryParams = rest.pop();
    }

    let args = [targetRouteName];
    args.push.apply(args, rest);
    args.push({ queryParams: queryParams.values });

    return router.urlFor.apply(router, args);
  }

  exports.hrefTo = hrefTo;
  exports.default = Ember.Helper.extend({
    compute([targetRouteName, ...rest], namedArgs) {
      if (namedArgs.params) {
        return hrefTo(this, ...namedArgs.params);
      } else {
        return hrefTo(this, targetRouteName, ...rest);
      }
    }
  });
});