define("ember-cp-validations/-private/ember-validator", ["exports", "ember-cp-validations/validators/base", "ember-validators"], function (_exports, _base, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _base.default.extend({
    validate() {
      let result = (0, _emberValidators.validate)(this.get('_evType'), ...arguments);
      if (result && typeof result === 'object') {
        return result.message ? result.message : this.createErrorMessage(result.type, result.value, result.context);
      }
      return result;
    }
  });
});