define('paper-data-table/components/paper-data-table-body', ['exports', 'paper-data-table/templates/components/paper-data-table-body'], function (exports, _paperDataTableBody) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['md-body'],
		attributeBindings: ['style'],
		layout: _paperDataTableBody.default,
		tagName: 'tbody',
		style: Ember.String.htmlSafe('position: relative;'),
		cellspacing: '0',
		cellpadding: '0'
	});
});