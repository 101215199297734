define("ember-macro-helpers/get-value-unsafe", ["exports", "ember-macro-helpers/get-value"], function (_exports, _getValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(options = {}) {
    let value = (0, _getValue.default)(options);
    if (value !== undefined) {
      return value;
    }
    return options.macro;
  }
});