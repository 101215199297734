define('paper-data-table/components/paper-data-table-cell', ['exports', 'paper-data-table/templates/components/paper-data-table-cell'], function (exports, _paperDataTableCell) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	const {
		Handlebars: { Utils: { escapeExpression } }
	} = Ember;

	exports.default = Ember.Component.extend({
		layout: _paperDataTableCell.default,
		tagName: 'td',
		classNameBindings: ['numeric:md-numeric', 'edit:md-clickable'],
		attributeBindings: ['style', 'colspan'],
		classNames: ['md-cell'],
		edit: false,
		showEdit: false,
		width: null,
		style: Ember.computed('width', function () {
			let width = escapeExpression(this.get('width'));
			if (width) {
				return Ember.String.htmlSafe(`width: ${width}px;`);
			} else {
				return undefined;
			}
		}),
		click() {
			if (this.get('onClick')) {
				this.get('onClick')();
			}
			this.set('showEdit', true);
		},
		actions: {
			close() {
				if (this.get('onClose')) {
					this.get('onClose')(this);
				}
			},
			toggleEdit() {
				this.toggleProperty('showEdit');
			}
		}
	});
});