define('paper-data-table/components/paper-data-table-row', ['exports', 'paper-data-table/templates/components/paper-data-table-row'], function (exports, _paperDataTableRow) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		layout: _paperDataTableRow.default,
		tagName: 'tr',
		classNames: ['md-row'],
		showEdit: false,
		attributeBindings: ['style', 'disabled'],
		style: Ember.computed('edit', 'onClick', function () {
			if (this.get('onClick') || this.get('edit')) {
				return Ember.String.htmlSafe('cursor: pointer;');
			}
			return Ember.String.htmlSafe('');
		}),

		click() {
			if (this.get('onClick')) {
				this.get('onClick')();
			}
			if (this.get('edit')) {
				this.set('showEdit', true);
			}
		},

		actions: {
			close() {
				if (this.get('onClose')) {
					this.get('onClose')(this);
				}
			},
			toggleEdit() {
				this.toggleProperty('showEdit');
			}
		}
	});
});