define("lodash/sample", ["exports", "lodash/_arraySample", "lodash/_baseSample", "lodash/isArray"], function (_exports, _arraySample, _baseSample, _isArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Gets a random element from `collection`.
   *
   * @static
   * @memberOf _
   * @since 2.0.0
   * @category Collection
   * @param {Array|Object} collection The collection to sample.
   * @returns {*} Returns the random element.
   * @example
   *
   * _.sample([1, 2, 3, 4]);
   * // => 2
   */
  function sample(collection) {
    var func = (0, _isArray.default)(collection) ? _arraySample.default : _baseSample.default;
    return func(collection);
  }
  var _default = _exports.default = sample;
});