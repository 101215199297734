define("liquid-fire/components/liquid-unless", ["exports", "liquid-fire/components/liquid-if"], function (_exports, _liquidIf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _liquidIf.default.extend({
    helperName: 'liquid-unless',
    inverted: true
  });
});