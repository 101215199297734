define("ember-truth-helpers/helpers/gt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.gt = gt;
  function gt([left, right], hash) {
    if (hash.forceNumber) {
      if (typeof left !== 'number') {
        left = Number(left);
      }
      if (typeof right !== 'number') {
        right = Number(right);
      }
    }
    return left > right;
  }
  var _default = _exports.default = Ember.Helper.helper(gt);
});