define("ember-awesome-macros/is-empty", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(key) {
    if (Ember.typeOf(key) === 'string') {
      return (0, _emberMacroHelpers.computed)(key, `${key}.{length,size}`, Ember.isEmpty);
    }
    return (0, _emberMacroHelpers.computed)(key, Ember.isEmpty);
  }
});