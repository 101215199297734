define("ember-awesome-macros/conditional", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberMacroHelpers.lazyCurriedComputed)((get, condition, expr1, expr2) => {
    return get(condition) ? get(expr1) : get(expr2);
  });
});