define("ember-awesome-macros/string/-utils", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeString = normalizeString;
  _exports.normalizeString2 = normalizeString2;
  function normalizeString(func) {
    return (0, _emberMacroHelpers.curriedComputed)(val => {
      if (!val) {
        return val;
      }
      return func(val);
    });
  }
  function normalizeString2(func, defaultValue = () => {}) {
    return (0, _emberMacroHelpers.lazyCurriedComputed)((get, stringKey, ...keys) => {
      let stringVal = get(stringKey);
      if (stringVal === undefined) {
        return defaultValue();
      }
      let prop = stringVal[func];
      if (typeof prop === 'function') {
        return prop.apply(stringVal, keys.map(get));
      }
      return prop;
    });
  }
});