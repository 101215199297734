define('paper-data-table/components/paper-data-table-edit-dialog', ['exports', 'paper-data-table/templates/components/paper-data-table-edit-dialog'], function (exports, _paperDataTableEditDialog) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		layout: _paperDataTableEditDialog.default,
		tagName: '',
		actions: {
			outsideClicked() {
				this.get('onClose')();
			}
		}
	});
});