define("ember-awesome-macros/array/first", ["exports", "ember-awesome-macros/array/-utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _utils.normalizeArray)({}, array => {
    return array.objectAt(0);
  });
});