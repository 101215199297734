define("ember-awesome-macros/not-equal", ["exports", "ember-awesome-macros/not", "ember-awesome-macros/eq"], function (_exports, _not, _eq) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    return (0, _not.default)((0, _eq.default)(...arguments));
  }
});