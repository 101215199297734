define("ember-i18n-iso-countries/langs/es", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "AF": "Afganistán",
    "AL": "Albania",
    "DZ": "Argelia",
    "AS": "Samoa Americana",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguila",
    "AQ": "Antártida",
    "AG": "Antigua y Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaiyán",
    "BS": "Bahamas",
    "BH": "Bahrein",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarús",
    "BE": "Bélgica",
    "BZ": "Belice",
    "BJ": "Benin",
    "BM": "Bermudas",
    "BT": "Bhután",
    "BO": "Bolivia (Estado Plurinacional de)",
    "BA": "Bosnia y Herzegovina",
    "BW": "Botswana",
    "BV": "Isla Bouvet",
    "BR": "Brasil",
    "IO": "Territorio Británico del Océano Índico",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Camboya",
    "CM": "Camerún",
    "CA": "Canadá",
    "CV": "Cabo Verde",
    "KY": "Islas Caimán",
    "CF": "República Centroafricana",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Isla de Navidad",
    "CC": "Islas Cocos (Keeling)",
    "CO": "Colombia",
    "KM": "Comoras",
    "CG": "Congo",
    "CD": "Congo (República Democrática del)",
    "CK": "Islas Cook",
    "CR": "Costa Rica",
    "CI": "Côte d'Ivoire",
    "HR": "Croacia",
    "CU": "Cuba",
    "CY": "Chipre",
    "CZ": "República Checa",
    "DK": "Dinamarca",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "República Dominicana",
    "EC": "Ecuador",
    "EG": "Egipto",
    "SV": "El Salvador",
    "GQ": "Guinea Ecuatorial",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Etiopía",
    "FK": "Islas Malvinas",
    "FO": "Islas Feroe",
    "FJ": "Fiji",
    "FI": "Finlandia",
    "FR": "Francia",
    "GF": "Guayana Francesa",
    "PF": "Polinesia Francesa",
    "TF": "Tierras Australes Francesas",
    "GA": "Gabón",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Alemania",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Grecia",
    "GL": "Groenlandia",
    "GD": "Granada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GN": "Guinea",
    "GW": "Guinea Bissau",
    "GY": "Guyana",
    "HT": "Haití",
    "HM": "Heard e Islas McDonald",
    "VA": "Santa Sede",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungría",
    "IS": "Islandia",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Irán (República Islámica de)",
    "IQ": "Iraq",
    "IE": "Irlanda",
    "IL": "Israel",
    "IT": "Italia",
    "JM": "Jamaica",
    "JP": "Japón",
    "JO": "Jordania",
    "KZ": "Kazajstán",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Corea, La República Popular Democrática de",
    "KR": "Corea, La República de",
    "KW": "Kuwait",
    "KG": "Kirguistán",
    "LA": "Lao, República Democrática Popular",
    "LV": "Letonia",
    "LB": "Líbano",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libia",
    "LI": "Liechtenstein",
    "LT": "Lituania",
    "LU": "Luxemburgo",
    "MO": "Macao",
    "MK": "Macedonia (Ex República Yugoslava de)",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malasia",
    "MV": "Maldivas",
    "ML": "Malí",
    "MT": "Malta",
    "MH": "Islas Marshall",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauricio",
    "YT": "Mayotte",
    "MX": "México",
    "FM": "Micronesia (Estados Federados de)",
    "MD": "Moldova (República de)",
    "MC": "Mónaco",
    "MN": "Mongolia",
    "MS": "Montserrat",
    "MA": "Marruecos",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Países Bajos",
    "NC": "Nueva Caledonia",
    "NZ": "Nueva Zelandia",
    "NI": "Nicaragua",
    "NE": "Níger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Isla Norfolk",
    "MP": "Isla Marianas del Norte",
    "NO": "Noruega",
    "OM": "Omán",
    "PK": "Pakistán",
    "PW": "Palau",
    "PS": "Estado de Palestina",
    "PA": "Panamá",
    "PG": "Papua Nueva Guinea",
    "PY": "Paraguay",
    "PE": "Perú",
    "PH": "Filipinas",
    "PN": "Pitcairn",
    "PL": "Polonia",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Reunión",
    "RO": "Rumania",
    "RU": "Federación de Rusia",
    "RW": "Rwanda",
    "SH": "Santa Helena, Ascensión y Tristán de Acuña",
    "KN": "Saint Kitts y Nevis",
    "LC": "Santa Lucía",
    "PM": "San Pedro y Miquelón",
    "VC": "San Vicente y las Granadinas",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Santo Tomé y Príncipe",
    "SA": "Arabia Saudita",
    "SN": "Senegal",
    "SC": "Seychelles",
    "SL": "Sierra Leona",
    "SG": "Singapur",
    "SK": "Eslovaquia",
    "SI": "Eslovenia",
    "SB": "Islas Salomón",
    "SO": "Somalia",
    "ZA": "Sudáfrica",
    "GS": "Georgia del Sur y las Islas Sandwich del Sur",
    "ES": "España",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard y Jan Mayen",
    "SZ": "Swazilandia",
    "SE": "Suecia",
    "CH": "Suiza",
    "SY": "República Árabe Siria",
    "TW": "Taiwán",
    "TJ": "Tayikistán",
    "TZ": "Tanzania, República Unida de",
    "TH": "Tailandia",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad y Tobago",
    "TN": "Túnez",
    "TR": "Turquía",
    "TM": "Turkmenistán",
    "TC": "Islas Turcas y Caicos",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ucrania",
    "AE": "Emiratos Árabes Unido",
    "GB": "Reino Unido de Gran Bretaña e Irlanda del Norte",
    "US": "Estados Unidos de América ",
    "UM": "Islas Ultramarinas Menores de los Estados Unidos",
    "UY": "Uruguay",
    "UZ": "Uzbekistán",
    "VU": "Vanuatu",
    "VE": "Venezuela (República Bolivariana de)",
    "VN": "Viet Nam",
    "VG": "Islas Vírgenes británicas",
    "VI": "Islas Vírgenes de los Estados Unidos",
    "WF": "Wallis y Futuna",
    "EH": "Sahara Occidental",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "AX": "Islas Åland",
    "BQ": "Bonaire, San Eustaquio y Saba",
    "CW": "Curaçao",
    "GG": "Guernsey",
    "IM": "Isla de Man",
    "JE": "Jersey",
    "ME": "Montenegro",
    "BL": "Saint Barthélemy",
    "MF": "Saint Martin (parte francesa)",
    "RS": "Serbia",
    "SX": "Sint Maarten (parte neerlandesa)",
    "SS": "Sudán del Sur"
  };
});