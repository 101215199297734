define("ember-composable-helpers/helpers/toggle-action", ["exports", "ember-composable-helpers/helpers/toggle", "ember-composable-helpers/-private/closure-action"], function (_exports, _toggle, _closureAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const closureToggle = _toggle.toggle;
  if (_closureAction.default) {
    closureToggle[_closureAction.default] = true;
  }
  var _default = _exports.default = Ember.Helper.helper(closureToggle);
});