define("ember-basic-dropdown/components/basic-dropdown-content", ["exports", "@ember-decorators/component", "ember-basic-dropdown/templates/components/basic-dropdown-content", "ember-basic-dropdown/utils/calculate-position", "ember-basic-dropdown/utils/scroll-helpers"], function (_exports, _component, _basicDropdownContent, _calculatePosition, _scrollHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function closestContent(el) {
    while (el && (!el.classList || !el.classList.contains('ember-basic-dropdown-content'))) {
      el = el.parentElement;
    }
    return el;
  }
  function waitForAnimations(element, callback) {
    window.requestAnimationFrame(function () {
      let computedStyle = window.getComputedStyle(element);
      if (computedStyle.animationName !== 'none' && computedStyle.animationPlayState === 'running') {
        let eventCallback = function () {
          element.removeEventListener('animationend', eventCallback);
          callback();
        };
        element.addEventListener('animationend', eventCallback);
      } else {
        callback();
      }
    });
  }

  /**
   * Evaluates if the given element is in a dropdown or any of its parent dropdowns.
   *
   * @param {HTMLElement} el
   * @param {String} dropdownId
   */
  function dropdownIsValidParent(el, dropdownId) {
    let closestDropdown = closestContent(el);
    if (closestDropdown) {
      let trigger = document.querySelector(`[aria-owns=${closestDropdown.attributes.id.value}]`);
      let parentDropdown = closestContent(trigger);
      return parentDropdown && parentDropdown.attributes.id.value === dropdownId || dropdownIsValidParent(parentDropdown, dropdownId);
    } else {
      return false;
    }
  }
  let BasicDropdownContent = _exports.default = (_dec = (0, _component.layout)(_basicDropdownContent.default), _dec2 = (0, _component.tagName)(''), _dec3 = Ember.computed, _dec4 = Ember.computed('destination'), _dec5 = Ember.computed('top', 'left', 'right', 'width', 'height', 'otherStyles'), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class BasicDropdownContent extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "isTouchDevice", Boolean(!!window && 'ontouchstart' in window));
      _defineProperty(this, "hasMoved", false);
      _defineProperty(this, "animationClass", '');
      _defineProperty(this, "transitioningInClass", 'ember-basic-dropdown--transitioning-in');
      _defineProperty(this, "transitionedInClass", 'ember-basic-dropdown--transitioned-in');
      _defineProperty(this, "transitioningOutClass", 'ember-basic-dropdown--transitioning-out');
      _defineProperty(this, "scrollableAncestors", []);
    }
    // CPs
    get animationEnabled() {
      if (this._animationEnabled !== undefined) {
        return this._animationEnabled;
      }
      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.environment !== 'test';
    }
    set animationEnabled(value) {
      this._animationEnabled = value;
    }
    get destinationElement() {
      return document.getElementById(this.destination);
    }
    get style() {
      let style = '';
      let {
        top,
        left,
        right,
        width,
        height,
        otherStyles
      } = this;
      if (otherStyles) {
        Object.keys(otherStyles).forEach(attr => {
          style += `${attr}: ${otherStyles[attr]};`;
        });
      }
      if (top) {
        style += `top: ${top};`;
      }
      if (left) {
        style += `left: ${left};`;
      }
      if (right) {
        style += `right: ${right};`;
      }
      if (width) {
        style += `width: ${width};`;
      }
      if (height) {
        style += `height: ${height}`;
      }
      return Ember.String.htmlSafe(style);
    }
    init() {
      super.init(...arguments);
      this.dropdownId = `ember-basic-dropdown-content-${this.dropdown.uniqueId}`;
      if (this.animationEnabled) {
        this.set('animationClass', this.transitioningInClass);
      }
    }

    // Methods
    setup(dropdownElement) {
      let triggerElement = document.querySelector(`[data-ebd-id=${this.dropdown.uniqueId}-trigger]`);
      this.handleRootMouseDown = e => {
        if (this.hasMoved || dropdownElement.contains(e.target) || triggerElement && triggerElement.contains(e.target)) {
          this.set('hasMoved', false);
          return;
        }
        if (dropdownIsValidParent(e.target, this.dropdownId)) {
          this.set('hasMoved', false);
          return;
        }
        this.dropdown.actions.close(e, true);
      };
      document.addEventListener(this.rootEventType, this.handleRootMouseDown, true);
      window.addEventListener('resize', this.runloopAwareReposition);
      window.addEventListener('orientationchange', this.runloopAwareReposition);
      if (this.isTouchDevice) {
        document.addEventListener('touchstart', this.touchStartHandler, true);
        document.addEventListener('touchend', this.handleRootMouseDown, true);
      }
      this.set('scrollableAncestors', this.getScrollableAncestors(triggerElement));
      this.addScrollHandling(dropdownElement);
    }
    teardown() {
      this.removeGlobalEvents();
      this.removeScrollHandling();
      this.scrollableAncestors = [];
      document.removeEventListener(this.rootEventType, this.handleRootMouseDown, true);
      if (this.isTouchDevice) {
        document.removeEventListener('touchstart', this.touchStartHandler, true);
        document.removeEventListener('touchend', this.handleRootMouseDown, true);
      }
    }
    animateIn(dropdownElement) {
      if (!this.animationEnabled) return;
      waitForAnimations(dropdownElement, () => {
        this.set('animationClass', this.transitionedInClass);
      });
    }
    animateOut(dropdownElement) {
      if (!this.animationEnabled) return;
      let parentElement = this.renderInPlace ? dropdownElement.parentElement.parentElement : dropdownElement.parentElement;
      let clone = dropdownElement.cloneNode(true);
      clone.id = `${clone.id}--clone`;
      clone.classList.remove(...this.transitioningInClass.split(' '));
      clone.classList.add(...this.transitioningOutClass.split(' '));
      parentElement.appendChild(clone);
      this.set('animationClass', this.transitioningInClass);
      waitForAnimations(clone, function () {
        parentElement.removeChild(clone);
      });
    }
    setupMutationObserver(dropdownElement) {
      this.mutationObserver = new MutationObserver(mutations => {
        let shouldReposition = false;
        shouldReposition = Array.prototype.slice.call(mutations[0].addedNodes).some(node => {
          return node.nodeName !== '#comment' && !(node.nodeName === '#text' && node.nodeValue === '');
        });
        shouldReposition = shouldReposition || Array.prototype.slice.call(mutations[0].removedNodes).some(node => {
          return node.nodeName !== '#comment' && !(node.nodeName === '#text' && node.nodeValue === '');
        });
        if (shouldReposition && this.shouldReposition) {
          shouldReposition = this.shouldReposition(mutations, this.dropdown);
        }
        if (shouldReposition) {
          this.runloopAwareReposition();
        }
      });
      this.mutationObserver.observe(dropdownElement, {
        childList: true,
        subtree: true
      });
    }
    teardownMutationObserver() {
      this.mutationObserver.disconnect();
      this.mutationObserver = null;
    }
    touchStartHandler() {
      document.addEventListener('touchmove', this.touchMoveHandler, true);
    }
    touchMoveHandler() {
      this.hasMoved = true;
      document.removeEventListener('touchmove', this.touchMoveHandler, true);
    }
    runloopAwareReposition() {
      Ember.run.join(this.dropdown.actions.reposition);
    }
    removeGlobalEvents() {
      window.removeEventListener('resize', this.runloopAwareReposition);
      window.removeEventListener('orientationchange', this.runloopAwareReposition);
    }

    // All ancestors with scroll (except the BODY, which is treated differently)
    getScrollableAncestors(triggerElement) {
      let scrollableAncestors = [];
      if (triggerElement) {
        let nextScrollable = (0, _calculatePosition.getScrollParent)(triggerElement.parentNode);
        while (nextScrollable && nextScrollable.tagName.toUpperCase() !== 'BODY' && nextScrollable.tagName.toUpperCase() !== 'HTML') {
          scrollableAncestors.push(nextScrollable);
          nextScrollable = (0, _calculatePosition.getScrollParent)(nextScrollable.parentNode);
        }
      }
      return scrollableAncestors;
    }
    addScrollHandling(dropdownElement) {
      if (this.preventScroll === true) {
        let wheelHandler = event => {
          if (dropdownElement.contains(event.target) || dropdownElement === event.target) {
            // Discover the amount of scrollable canvas that is within the dropdown.
            const availableScroll = (0, _scrollHelpers.getAvailableScroll)(event.target, dropdownElement);

            // Calculate what the event's desired change to that scrollable canvas is.
            let {
              deltaX,
              deltaY
            } = (0, _scrollHelpers.getScrollDeltas)(event);

            // If the consequence of the wheel action would result in scrolling beyond
            // the scrollable canvas of the dropdown, call preventDefault() and clamp
            // the value of the delta to the available scroll size.
            if (deltaX < availableScroll.deltaXNegative) {
              deltaX = availableScroll.deltaXNegative;
              event.preventDefault();
            } else if (deltaX > availableScroll.deltaXPositive) {
              deltaX = availableScroll.deltaXPositive;
              event.preventDefault();
            } else if (deltaY < availableScroll.deltaYNegative) {
              deltaY = availableScroll.deltaYNegative;
              event.preventDefault();
            } else if (deltaY > availableScroll.deltaYPositive) {
              deltaY = availableScroll.deltaYPositive;
              event.preventDefault();
            }

            // Add back in the default behavior for the two good states that the above
            // `preventDefault()` code will break.
            // - Two-axis scrolling on a one-axis scroll container
            // - The last relevant wheel event if the scroll is overshooting

            // Also, don't attempt to do this if both of `deltaX` or `deltaY` are 0.
            if (event.defaultPrevented && (deltaX || deltaY)) {
              (0, _scrollHelpers.distributeScroll)(deltaX, deltaY, event.target, dropdownElement);
            }
          } else {
            // Scrolling outside of the dropdown is prohibited.
            event.preventDefault();
          }
        };
        document.addEventListener('wheel', wheelHandler, {
          capture: true,
          passive: false
        });
        this.removeScrollHandling = () => {
          document.removeEventListener('wheel', wheelHandler, {
            capture: true,
            passive: false
          });
        };
      } else {
        this.addScrollEvents();
        this.removeScrollHandling = this.removeScrollEvents;
      }
    }

    // Assigned at runtime to ensure that changes to the `preventScroll` property
    // don't result in not cleaning up after ourselves.
    removeScrollHandling() {}

    // These two functions wire up scroll handling if `preventScroll` is false.
    // These trigger reposition of the dropdown.
    addScrollEvents() {
      window.addEventListener('scroll', this.runloopAwareReposition);
      this.scrollableAncestors.forEach(el => {
        el.addEventListener('scroll', this.runloopAwareReposition);
      });
    }
    removeScrollEvents() {
      window.removeEventListener('scroll', this.runloopAwareReposition);
      this.scrollableAncestors.forEach(el => {
        el.removeEventListener('scroll', this.runloopAwareReposition);
      });
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "animationEnabled", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "animationEnabled"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "destinationElement", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "destinationElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "style", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "style"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setup", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "setup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "teardown", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "teardown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "animateIn", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "animateIn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "animateOut", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "animateOut"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setupMutationObserver", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "setupMutationObserver"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "teardownMutationObserver", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "teardownMutationObserver"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "touchStartHandler", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "touchStartHandler"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "touchMoveHandler", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "touchMoveHandler"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "runloopAwareReposition", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "runloopAwareReposition"), _class2.prototype)), _class2)) || _class) || _class);
});