define("lodash/_getAllKeys", ["exports", "lodash/_baseGetAllKeys", "lodash/_getSymbols", "lodash/keys"], function (_exports, _baseGetAllKeys, _getSymbols, _keys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Creates an array of own enumerable property names and symbols of `object`.
   *
   * @private
   * @param {Object} object The object to query.
   * @returns {Array} Returns the array of property names and symbols.
   */
  function getAllKeys(object) {
    return (0, _baseGetAllKeys.default)(object, _keys.default, _getSymbols.default);
  }
  var _default = _exports.default = getAllKeys;
});