define("liquid-fire/transitions/flex-grow", ["exports", "liquid-fire"], function (_exports, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = flexGrow;
  function flexGrow(opts) {
    (0, _liquidFire.stop)(this.oldElement);
    return _liquidFire.Promise.all([(0, _liquidFire.animate)(this.oldElement, {
      'flex-grow': 0
    }, opts), (0, _liquidFire.animate)(this.newElement, {
      'flex-grow': [1, 0]
    }, opts)]);
  }
});