define("ember-macro-helpers/reads", ["exports", "ember-macro-helpers/writable"], function (_exports, _writable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _writable.default;
    }
  });
});