define("lodash/_stringToArray", ["exports", "lodash/_asciiToArray", "lodash/_hasUnicode", "lodash/_unicodeToArray"], function (_exports, _asciiToArray, _hasUnicode, _unicodeToArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Converts `string` to an array.
   *
   * @private
   * @param {string} string The string to convert.
   * @returns {Array} Returns the converted array.
   */
  function stringToArray(string) {
    return (0, _hasUnicode.default)(string) ? (0, _unicodeToArray.default)(string) : (0, _asciiToArray.default)(string);
  }
  var _default = _exports.default = stringToArray;
});