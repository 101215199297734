define("ember-ajax/services/ajax", ["exports", "ember-ajax/mixins/ajax-request"], function (_exports, _ajaxRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AjaxServiceClass = void 0;
  const AjaxService = Ember.Service.extend(_ajaxRequest.default);
  var _default = _exports.default = AjaxService; // DO NOT DELETE: this is how TypeScript knows how to look up your services.
  class AjaxServiceClass extends AjaxService {}
  _exports.AjaxServiceClass = AjaxServiceClass;
});