define("ember-power-calendar/helpers/power-calendar-format-date", ["exports", "ember-power-calendar-utils"], function (_exports, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.powerCalendarFormatDate = powerCalendarFormatDate;
  function powerCalendarFormatDate([date, format], {
    locale
  }) {
    return (0, _emberPowerCalendarUtils.formatDate)(date, format, locale);
  }
  var _default = _exports.default = Ember.Helper.helper(powerCalendarFormatDate);
});