define("ember-composable-helpers/helpers/values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.values = values;
  function values([object]) {
    if (!object) {
      return object;
    }
    return Object.values(object);
  }
  var _default = _exports.default = Ember.Helper.helper(values);
});