define("ember-power-calendar/templates/components/power-calendar/nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tVznCn3/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"nav\"],[14,0,\"ember-power-calendar-nav\"],[12],[2,\"\\n\"],[6,[37,3],[[35,0,[\"actions\",\"changeCenter\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"button\"],[14,0,\"ember-power-calendar-nav-control ember-power-calendar-nav-control--previous\"],[15,\"onclick\",[30,[36,1],[[32,0],[35,0,[\"actions\",\"moveCenter\"]],-1,\"month\",[35,0]],null]],[14,4,\"button\"],[12],[2,\"«\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"ember-power-calendar-nav-title\"],[12],[2,\"\\n\"],[6,[37,3],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,[[35,0]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,2],[[35,0,[\"center\"]],\"MMMM YYYY\"],[[\"locale\"],[[35,0,[\"locale\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,3],[[35,0,[\"actions\",\"changeCenter\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"button\"],[14,0,\"ember-power-calendar-nav-control ember-power-calendar-nav-control--next\"],[15,\"onclick\",[30,[36,1],[[32,0],[35,0,[\"actions\",\"moveCenter\"]],1,\"month\",[35,0]],null]],[14,4,\"button\"],[12],[2,\"»\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"calendar\",\"action\",\"power-calendar-format-date\",\"if\"]}",
    "moduleName": "ember-power-calendar/templates/components/power-calendar/nav.hbs"
  });
});