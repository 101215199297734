define('ember-render-to-string/components/render-to-string', ['exports', 'ember-render-to-string/templates/components/render-to-string'], function (exports, _renderToString) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _renderToString.default,

    /**
     * Type of result to return:
     * – 'html' – node.innerHTML
     * – 'text' – node.innerText
     * – 'dom' – node
     *
     * @default 'html'
     * @type {String}
     * @public
     */
    content: 'html',

    /**
     * Tag name for HTML node. Accept any valid for document.createElement() method tag.
     * Usefull only with `content='dom'`
     *
     * @default 'div'
     * @type {String}
     * @public
     */
    destElTag: 'div',

    /**
     * Action to call after yielded template has been rendered. Hook accepts one
     * argument which content depends on `content` property.
     *
     * @default NoOp
     * @public
     */
    afterRender() {},

    // Fastboot service
    fastboot: Ember.computed({
      get() {
        let owner = Ember.getOwner(this);
        return owner.lookup('service:fastboot');
      }
    }),

    isNotFastboot: Ember.computed({
      get() {
        let fastboot = Ember.get(this, 'fastboot');
        return !(fastboot && Ember.get(fastboot, 'isFastBoot'));
      }
    }),

    // Create element based on `destElTag`
    destEl: Ember.computed('destElTag', {
      get() {
        let tag = Ember.get(this, 'destElTag');
        return document.createElement(tag);
      }
    }),

    didRender() {
      this._super(...arguments);

      // Trigger afterRender hook only in non-fastboot mode.
      // Because we cannot create destEl in fastboot.
      if (Ember.get(this, 'isNotFastboot')) {
        this._extractHTML();
      }
    },

    /**
     * Validate passed action and call it with content
     *
     * @private
     */
    _extractHTML() {
      let afterRender = Ember.get(this, 'afterRender');
      (false && !(typeof afterRender === 'function') && Ember.assert(`afterRender must be a function. You provided: ${typeof afterRender}`, typeof afterRender === 'function'));

      afterRender(this._getContent());
    },

    /**
     * Get content from `destEl` based on `content` property.
     *
     * @return {String|HTMLNode}
     * @private
     */
    _getContent() {
      let destEl = Ember.get(this, 'destEl');
      let content = Ember.get(this, 'content');

      switch (content) {
        case 'dom':
          return destEl;
        case 'text':
          return destEl.innerText;
        default:
          return destEl.innerHTML;
      }
    }
  });
});