define('paper-data-table/components/paper-table-select', ['exports', 'paper-data-table/templates/components/paper-table-select', 'ember-paper/components/paper-select'], function (exports, _paperTableSelect, _paperSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _paperSelect.default.extend({
    layout: _paperTableSelect.default,
    tagName: 'div',
    classNames: ['page-select']
  });
});