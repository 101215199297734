define("ember-macro-helpers/collapse-keys", ["exports", "ember-macro-helpers/collapse-key"], function (_exports, _collapseKey) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.collapseKeysWithMap = collapseKeysWithMap;
  _exports.default = _default;
  function collapseKeysWithMap(keys) {
    let collapsedKeys = [];
    let keyMap = [];
    keys.forEach(key => {
      let array = (0, _collapseKey.default)(key);
      collapsedKeys = collapsedKeys.concat(array);
      let i;
      if (keyMap.length) {
        i = keyMap[keyMap.length - 1] + 1;
      } else {
        i = 0;
      }
      keyMap = keyMap.concat(array.map(() => i));
    });
    return {
      collapsedKeys,
      keyMap
    };
  }
  function _default(keys) {
    return collapseKeysWithMap(keys).collapsedKeys;
  }
});