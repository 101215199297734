define("ember-composability-tools/templates/render-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "yjvg45rx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[18,1,[[35,0]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"yieldHash\",\"destinationElement\",\"in-element\",\"shouldRender\",\"if\"]}",
    "moduleName": "ember-composability-tools/templates/render-block.hbs"
  });
});