define("ember-ajax/raw", ["exports", "ember-ajax/ajax-request"], function (_exports, _ajaxRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = raw;
  /**
   * Same as `request` except it resolves an object with
   *
   *   {response, textStatus, jqXHR}
   *
   * Useful if you need access to the jqXHR object for headers, etc.
   *
   * @public
   */
  function raw(url, options) {
    const ajax = _ajaxRequest.default.create();
    return ajax.raw(url, options);
  }
});