define("ember-cli-clipboard/components/copy-button", ["exports", "ember-cli-clipboard/templates/components/copy-button", "clipboard"], function (_exports, _copyButton, _clipboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const CLIPBOARD_EVENTS = ['success', 'error'];
  let CopyButtonComponent = _exports.default = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class CopyButtonComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "layout", _copyButton.default);
      _defineProperty(this, "tagName", '');
      /**
       * If true - scope event listener to this element
       * If false - scope event listener to document.body (ClipboardJS)
       * @property {Boolean} delegateClickEvent
       */
      _defineProperty(this, "delegateClickEvent", true);
    }
    /**
     * Assigns button element an id
     * @returns {Void}
     */
    setupElement(element) {
      element.id = Ember.guidFor(this);
      this._buttonElement = element;
    }

    /**
     * Registers ClipboardJS object with component
     * @private
     * @returns {Void}
     */
    registerClipboard() {
      if (this.clipboard) {
        this.clipboard.destroy();
      }
      const clipboard = this._createClipboard();
      this._registerActions(clipboard);
      this.clipboard = clipboard;
    }

    /**
     * Destroys `ClipboardJS` instance
     * @returns {Void}
     */
    destroyClipboard() {
      if (this.clipboard) {
        this.clipboard.destroy();
      }
    }

    /**
     * Creates new `ClipboardJS` instance
     * @private
     * @returns {Object} newly created ClipboardJS object
     */
    _createClipboard() {
      const {
        clipboardText: text,
        container,
        delegateClickEvent
      } = this;
      const trigger = delegateClickEvent === false ? this._buttonElement : `#${this._buttonElement.id}`;
      return new _clipboard.default(trigger, {
        text: typeof text === 'function' ? text : undefined,
        container: typeof container === 'string' ? document.querySelector(container) : container
      });
    }

    /**
     * Registers Ember Actions with ClipboardJS events
     * @private
     * @param {Object} clipboard - ClipboardJS object
     * @returns {Void}
     */
    _registerActions(clipboard) {
      CLIPBOARD_EVENTS.forEach(event => {
        clipboard.on(event, () => {
          if (!this._buttonElement.disabled) {
            const action = this[event];
            if (typeof action === 'string') {
              // eslint-disable-next-line ember/closure-actions
              this.sendAction(action, ...arguments);
            } else {
              action && action(...arguments);
            }
          }
        });
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "setupElement", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setupElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerClipboard", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "registerClipboard"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "destroyClipboard", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "destroyClipboard"), _class.prototype)), _class));
});