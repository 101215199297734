define("ember-truth-helpers/helpers/xor", ["exports", "ember-truth-helpers/utils/truth-convert"], function (_exports, _truthConvert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.xor = xor;
  function xor(params) {
    return (0, _truthConvert.default)(params[0]) !== (0, _truthConvert.default)(params[1]);
  }
  var _default = _exports.default = Ember.Helper.helper(xor);
});