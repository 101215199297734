define("lodash/_asciiSize", ["exports", "lodash/_baseProperty"], function (_exports, _baseProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Gets the size of an ASCII `string`.
   *
   * @private
   * @param {string} string The string inspect.
   * @returns {number} Returns the string size.
   */
  var asciiSize = (0, _baseProperty.default)('length');
  var _default = _exports.default = asciiSize;
});